import { useMemo } from 'react';
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { currencyFormater } from '../common';


export const CleansTable = ({ data }: { data: any }) => {
   
    interface CleansRow {
        cleaner: string;
        count: number;
        payout: number;
    }

    const rows = useMemo(() => {
        let cleanerMap = new Map<string, CleansRow>();
        data.forEach((x: any) => {
            if (cleanerMap.has(x.cleaner)) {
                let temp = cleanerMap.get(x.cleaner)!;
                temp.count += 1;
                temp.payout += Number(x.cleaning_fee);
            } else {
                cleanerMap.set(x.cleaner,
                    {
                        cleaner: x.cleaner,
                        count: 1,
                        payout: Number(x.cleaning_fee)
                    });
            }
        });

        return Array.from(cleanerMap.values());

    }, [data])
    

    const percentTemplate = (row: any) => {
        return `${Math.round(row.count / data.length * 100)}%`
    }
    
    const countCleansTemplate = (row: any) => {
        return row.count;
    }

    const cleanerTemplate = (row: any) => {
        return row.cleaner;
    }
    
    const payoutTemplate = (row: any) => {
        return (currencyFormater.format(row.payout))
    }

    return (
        <DataTable value={rows} scrollable scrollHeight="400px">
            <Column field="cleaner" header="Cleaner" body={cleanerTemplate}></Column>
            <Column field="count" header="# Cleans" body={countCleansTemplate}></Column>
            <Column field="payout" header="Payout" body={payoutTemplate} ></Column>
            <Column field="percent" header="Percent" body={percentTemplate}></Column>            
        </DataTable>
    )
}
